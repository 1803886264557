import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <aside id="left-panel" className="left-panel">
      <nav className="navbar navbar-expand-sm navbar-default">
        <div id="main-menu" className="main-menu collapse navbar-collapse">
          <ul className="nav navbar-nav">
            <li>
              <Link to="/users">
                {" "}
                <i className="menu-icon ti-user"></i>Utilisateurs{" "}
              </Link>
            </li>
            <li>
              <Link to="/mailings">
                {" "}
                <i className="menu-icon ti-list"></i>Mailing{" "}
              </Link>
            </li>
            <li>
              <Link to="/needs">
                {" "}
                <i className="menu-icon ti-files"></i>Besoins{" "}
              </Link>
            </li>
            <li>
              <Link to="/candidats">
                {" "}
                <i className="menu-icon ti-id-badge"></i>Profils{" "}
              </Link>
            </li>
            <li>
              <Link to="/emails/create">
                {" "}
                <i className="menu-icon ti-pencil-alt"></i>Créer Un Email{" "}
              </Link>
            </li>
            <li>
              <Link to="/mails">
                {" "}
                <i className="menu-icon ti-email"></i>Emails Envoyés{" "}
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </aside>
  );
};

export default Header;
