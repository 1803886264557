import axios from "axios";
import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../Config/config";
import { displayAlert } from "../../helpers/alert";

const defaultEmail = {
  introduction: "",
  end: "",
  profilsSubject: "",
  needsSubject: "",
};

const CreateEmail = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState(defaultEmail);
  const [candidats, setCandidats] = useState([]);
  const [needs, setNeeds] = useState([]);

  const [selectedNeeds, setSelectedNeeds] = useState([]);
  const [selectedCandidats, setSelectedCandidats] = useState([]);
  const [mailingFile, setMailingFile] = useState();
  const [formErrors, setFormErrors] = useState(defaultEmail);

  async function save() {
    setFormErrors(defaultEmail);
    let formData = new FormData();
    formData.append("profils", JSON.stringify(getIds(selectedCandidats)));
    formData.append("needs", JSON.stringify(getIds(selectedNeeds)));
    formData.append("mailings", mailingFile);
    formData.append("profilsSubject", email.profilsSubject);
    formData.append("needsSubject", email.needsSubject);
    formData.append("introduction", email.introduction);
    formData.append("end", email.end);

    await axios
      .post(`${baseURL}/emails/create`, formData)
      .then((response) => {
        if (response.data.error) return;
        displayAlert("success", "Email Envoyer !", "");
        navigate("/candidats");
      })
      .catch((err) => {
        setFormErrors({ ...defaultEmail, ...err.response.data });
        displayAlert("error", "Erreur est survenue !", "Veuiller réessayer !");
      });
  }

  function getIds(list) {
    return list.map((item) => {
      return JSON.parse(item).id;
    });
  }

  function getCandidats() {
    axios
      .get(`${baseURL}/candidats`)
      .then((response) => {
        if (response.data.error) return;
        setCandidats(response.data.data);
      })
      .catch((err) => {
        displayAlert("error", "Erreur est survenue !", "Veuiller réessayer !");
      });
  }

  function getNeeds() {
    axios
      .get(`${baseURL}/needs`)
      .then((response) => {
        if (response.data.error) return;
        setNeeds(response.data.data);
      })
      .catch((err) => {
        displayAlert("error", "Erreur est survenue !", "Veuiller réessayer !");
      });
  }

  function checkboxHandling(value, state, setState) {
    if (!state.includes(value)) {
      setState((prevState) => [...prevState, value]);
    } else {
      const filteredState = state.filter(function (item) {
        return item !== value;
      });
      setState(filteredState);
    }
  }

  useEffect(() => {
    getNeeds();
    getCandidats();
  }, []);

  return (
    <>
      <div className="breadcrumbs">
        <div className="breadcrumbs-inner">
          <div className="row m-0">
            <div className="col-sm-4">
              <div className="page-header float-left">
                <div className="page-title">
                  <h1>Créer un Email </h1>
                </div>
              </div>
            </div>
            <div className="col-sm-8"></div>
          </div>
        </div>
      </div>
      <div className="content row">
        <div className="content col-lg-6 col-12">
          <div className="animated">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="form-group col-12">
                    <label
                      htmlFor="profilsSubject"
                      className=" form-control-label"
                    >
                      Objet pour les Profils
                    </label>
                    <input
                      type="text"
                      id="profilsSubject"
                      onChange={(e) => {
                        setEmail((prevState) => ({
                          ...prevState,
                          profilsSubject: e.target.value,
                        }));
                      }}
                      placeholder="Enter l'Object de l'email des profils"
                      className={
                        formErrors.profilsSubject !== ""
                          ? "form-control border-danger"
                          : "form-control"
                      }
                    />
                    <small>
                      <code>{formErrors.profilsSubject}</code>
                    </small>
                  </div>
                  <div className="form-group col-12">
                    <label
                      htmlFor="needsSubject"
                      className=" form-control-label"
                    >
                      Objet pour les Besoins
                    </label>
                    <input
                      type="text"
                      id="needsSubject"
                      onChange={(e) => {
                        setEmail((prevState) => ({
                          ...prevState,
                          needsSubject: e.target.value,
                        }));
                      }}
                      placeholder="Enter l'Object de l'email des Besoins"
                      className={
                        formErrors.needsSubject !== ""
                          ? "form-control border-danger"
                          : "form-control"
                      }
                    />
                    <small>
                      <code>{formErrors.needsSubject}</code>
                    </small>
                  </div>
                  <div className="form-group col-12">
                    <label
                      htmlFor="introduction"
                      className=" form-control-label"
                    >
                      Introduction (Optionnel)
                    </label>
                    <input
                      type="text"
                      id="introduction"
                      onChange={(e) => {
                        setEmail((prevState) => ({
                          ...prevState,
                          introduction: e.target.value,
                        }));
                      }}
                      placeholder="Enter l'Introduction"
                      className={
                        formErrors.introduction !== ""
                          ? "form-control border-danger"
                          : "form-control"
                      }
                    />
                    <small>
                      <code>{formErrors.introduction}</code>
                    </small>
                  </div>

                  <hr />

                  <div className="form-group col-12">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Liste des Mailing</Accordion.Header>
                        <Accordion.Body>
                          <div className="row form-group col-12 pl-5">
                            <label
                              htmlFor="mailingList"
                              className="form-control-label"
                            >
                              Importer
                            </label>
                            <input
                              type="file"
                              id="mailingList"
                              onChange={(e) =>
                                setMailingFile(e.target.files[0])
                              }
                              className="form-input"
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      {needs.length > 0 ? (
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            Liste des Bessoins
                          </Accordion.Header>
                          <Accordion.Body>
                            {needs.map((need, key) => {
                              return (
                                <div
                                  key={key}
                                  className="row form-group col-12 pl-5"
                                >
                                  <label className=" form-control-label">
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        checkboxHandling(
                                          e.target.value,
                                          selectedNeeds,
                                          setSelectedNeeds
                                        );
                                      }}
                                      value={JSON.stringify(need)}
                                      className="form-check-input"
                                    />
                                    {need.title}
                                  </label>
                                </div>
                              );
                            })}{" "}
                          </Accordion.Body>
                        </Accordion.Item>
                      ) : (
                        ""
                      )}
                      {candidats.length > 0 ? (
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Liste des Profils</Accordion.Header>
                          <Accordion.Body>
                            {candidats.map((candidat, key) => {
                              return (
                                <div
                                  key={key}
                                  className="row form-group col-12 pl-5"
                                >
                                  <label className=" form-control-label">
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        checkboxHandling(
                                          e.target.value,
                                          selectedCandidats,
                                          setSelectedCandidats
                                        );
                                      }}
                                      value={JSON.stringify(candidat)}
                                      className="form-check-input"
                                    />
                                    {candidat.name}
                                  </label>
                                </div>
                              );
                            })}{" "}
                          </Accordion.Body>
                        </Accordion.Item>
                      ) : (
                        ""
                      )}
                    </Accordion>
                  </div>

                  <hr />

                  <div className="form-group col-12">
                    <label htmlFor="end" className=" form-control-label">
                      Fin (Optionnel)
                    </label>
                    <input
                      type="text"
                      id="end"
                      placeholder="Enter la Signature"
                      onChange={(e) => {
                        setEmail((prevState) => ({
                          ...prevState,
                          end: e.target.value,
                        }));
                      }}
                      className={
                        formErrors.end !== ""
                          ? "form-control border-danger"
                          : "form-control"
                      }
                    />
                    <small>
                      <code>{formErrors.end}</code>
                    </small>
                  </div>
                </div>
                <hr />
                <div className="d-flex flex-row-reverse text-right">
                  <button
                    type="button"
                    onClick={save}
                    className="btn btn-primary btn-sm mx-2"
                  >
                    Envoyer
                  </button>
                  <Link
                    className="btn btn-link btn-sm text-secondary mx-2"
                    to={"/users"}
                  >
                    Annuler
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content col-lg-6 col-12">
          <div className="animated">
            <div className="card">
              <div className="card-header">Email Preview</div>
              <div className="card-body">
                <div className="col-12">
                  {" "}
                  Objet pour les profils : {email?.profilsSubject}{" "}
                </div>
                <div className="col-12">
                  {" "}
                  Objet pour les Besoins : {email?.needsSubject}{" "}
                </div>
                <hr />
                <div className="col-12 mt-1"> Bonjour, </div>
                <div className="col-12 mt-1"> {email?.introduction} </div>
                <div className="col-12">
                  Profils Recherchés :{" "}
                  {selectedNeeds.length > 0 &&
                    selectedNeeds.map((need, key) => {
                      return (
                        <div key={key} className="card">
                          <div className="card-body">
                            <div>Titre : {JSON.parse(need).title}</div>
                            <div>
                              Lieu de Mission : {JSON.parse(need).location}
                            </div>
                            <div style={{ whiteSpace: "pre-line" }}>
                              Description : {JSON.parse(need).description}
                            </div>
                          </div>
                        </div>
                      );
                    })}{" "}
                </div>
                <div className="col-12">
                  Profils disponibles :{" "}
                  {selectedCandidats.length > 0 &&
                    selectedCandidats.map((candidat, key) => {
                      return (
                        <div key={key} className="card">
                          <div className="card-body">
                            <div>Nom : {JSON.parse(candidat).name}</div>
                            <div>
                              Experience : {JSON.parse(candidat).experience}
                            </div>
                            <div>
                              Technologies : {JSON.parse(candidat).technologies}
                            </div>
                            <div style={{ whiteSpace: "pre-line" }}>
                              Description : {JSON.parse(candidat).description}
                            </div>
                          </div>
                        </div>
                      );
                    })}{" "}
                </div>
                <div className="col-12 mt-1"> {email?.end} </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateEmail;
