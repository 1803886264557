import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, redirect, useNavigate } from "react-router-dom";
import { baseURL } from "../Config/config";
import { store } from "../slices/userSlice";

const TopHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document
      .querySelector(".menutoggle")
      .addEventListener("click", function (e) {
        document.querySelector("body").classList.toggle("open");
        document.querySelector("#left-panel").classList.toggle("open-menu");
      });
  }, []);

  async function logout() {
    try {
      await axios.post(`${baseURL}/logout`).then((response) => {
        dispatch(store(undefined));
        navigate("/login");
      });
    } catch (error) {
      if (error.response.data.message) {
      }
    }
  }

  return (
    <header id="header" className="header">
      <div className="top-left">
        <div className="navbar-header">
          <a className="navbar-brand" href="./">
            <img src="../assets/images/logo.png" alt="Logo" height={30} />
          </a>
          <a className="navbar-brand hidden" href="./">
            <img src="../assets/images/logo2.png" alt="Logo" height={30} />
          </a>
          <a className="menutoggle">
            <i className="fa fa-bars"></i>
          </a>
        </div>
      </div>
      <div className="top-right">
        <div className="header-menu">
          <div className="user-area dropdown float-right">
            <a
              href="#"
              className="dropdown-toggle active"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                className="user-avatar rounded-circle"
                src="../assets/images/admin.jpg"
                alt="User Avatar"
              />
            </a>

            <div className="user-menu dropdown-menu">
              <a className="nav-link" href="#" onClick={logout}>
                <i className="fa fa-power -off"></i>Déconnecter
              </a>
              <Link className="nav-link" to={"/user/update-password"}>
                <i className="fa fa-power -off"></i>Changer mot de passe
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default TopHeader;
