import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../Config/config";
import { useSelector, useDispatch } from "react-redux";
import { store } from "../slices/userSlice";

const Login = () => {
  const navigate = useNavigate();

  const user = useSelector((state) => state.user.user.payload);
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState({
    email: "",
    password: "",
    status: "",
  });

  function initErrorMessage() {
    setErrMsg({
      email: "",
      password: "",
      status: "",
    });
  }

  async function login() {
    initErrorMessage();
    try {
      await axios
        .post(`${baseURL}/login`, { email, password })
        .then((response) => {
          dispatch(store(response.data.data));
        });
    } catch (error) {
      if (error.response.data.message) {
        setErrMsg(error.response.data.message);
      }
    }
  }

  useEffect(() => {
    if (user === undefined) return;
    navigate("/");
  }, [user]);
  return (
    <div className="sufee-login d-flex align-content-center flex-wrap">
      <div className="container">
        <div className="login-content">
          <div className="login-logo">
            <a href="index.html">
              <img
                className="align-content"
                height={250}
                src="../assets/images/logo.png"
                alt=""
              />
            </a>
          </div>
          <div className="login-form">
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="form-control"
                placeholder="Email"
              />
            </div>
            <div className="form-group">
              <label>Mot de passe</label>
              <input
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                className="form-control"
                placeholder="Mot de passe"
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-flat m-b-30 m-t-30"
              onClick={() => login()}
            >
              Connecter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
