import React from "react";

const EmptyData = () => {
  return (
    <div className="card">
      <div className="column">
        <div className="col-12 align-items-center justify-content-center text-center">
          <img
            src="../../../assets/images/not_found.jpg"
            alt="not found"
            width={200}
          />
        </div>
        <div className="col-12 align-items-center justify-content-center text-center">
          Aucune résultat trouvée !
        </div>
      </div>
    </div>
  );
};

export default EmptyData;
