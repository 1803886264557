import React from "react";
import { Link } from "react-router-dom";

const UsersCards = ({ users }) => {
  return (
    <>
      <div className="row">
        {users &&
          users.map((user, key) => {
            return (
              <div className="col-md-6 col-12" key={key}>
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <h4 className="text-uppercase col-8">{user.name}</h4>
                      <span className="col-4 float-right">
                        <Link to={`/user/${user.id}`}>
                          <i className="fa fa-pencil-square-o"></i>{" "}
                          <span className="d-md-none d-lg-inline">
                            Modifier
                          </span>
                        </Link>
                      </span>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-12">
                        <small>Email : {user.email}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default UsersCards;
