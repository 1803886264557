import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../Config/config";
import { displayAlert } from "../../helpers/alert";

const initFormErrors = {
  name: "",
  email: "",
  password: "",
  confirm_password: "",
};

const CreateUser = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formErrors, setFormErrors] = useState(initFormErrors);

  async function save() {
    await setFormErrors(initFormErrors);
    if (password !== confirmPassword || password === "") {
      setFormErrors({
        ...initFormErrors,
        ...{ confirm_password: "veuiller confirmer votre mot de passe" },
      });
      displayAlert(
        "error",
        "Mot de passe incorrecte !",
        "veuiller confirmer votre mot de passe !"
      );
      return;
    }
    await axios
      .post(`${baseURL}/users/create`, {
        name: name,
        email: email,
        password: password,
        confirm_password: confirmPassword,
      })
      .then((response) => {
        if (response.data.error) return;
        displayAlert("success", "Utilisateur Créée !", "");
        navigate("/users");
      })
      .catch((err) => {
        setFormErrors({ ...initFormErrors, ...err.response.data });
        displayAlert("error", "Erreur est survenue !", "Veuiller réessayer !");
      });
  }

  return (
    <>
      <div className="breadcrumbs">
        <div className="breadcrumbs-inner">
          <div className="row m-0">
            <div className="col-sm-4">
              <div className="page-header float-left">
                <div className="page-title">
                  <h1>Créer un utilisateur </h1>
                </div>
              </div>
            </div>
            <div className="col-sm-8"></div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="animated">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="name" className=" form-control-label">
                    Nom
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    id="name"
                    placeholder="Enter le nom"
                    className={
                      formErrors.name !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                  />
                  <small>
                    <code>{formErrors.name}</code>
                  </small>
                </div>
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="email" className=" form-control-label">
                    Email
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    id="email"
                    placeholder="Enter l'email"
                    className={
                      formErrors.email !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                  />
                  <small>
                    <code>{formErrors.email}</code>
                  </small>
                </div>
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="name" className=" form-control-label">
                    Mot de passe
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    id="password"
                    placeholder="Enter le mot de passe"
                    className={
                      formErrors.password !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                  />
                  <small>
                    <code>{formErrors.password}</code>
                  </small>
                </div>
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="name" className=" form-control-label">
                    Confirmer le mot de passe
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    id="confirm_password"
                    placeholder="Confirmer le mot de passe"
                    className={
                      formErrors.confirm_password !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                  />
                  <small>
                    <code>{formErrors.confirm_password}</code>
                  </small>
                </div>
              </div>
              <hr />
              <div className="d-flex flex-row-reverse text-right">
                <button
                  type="button"
                  onClick={save}
                  className="btn btn-primary btn-sm mx-2"
                >
                  Enregistrer
                </button>
                <Link
                  className="btn btn-link btn-sm text-secondary mx-2"
                  to={"/users"}
                >
                  Annuler
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateUser;
