import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { baseURL } from "../../Config/config";
import { displayAlert } from "../../helpers/alert";

const initFormErrors = {
  name: "",
  email: "",
};

export default function UpdateUser() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [user, setUser] = useState();
  const [formErrors, setFormErrors] = useState(initFormErrors);

  function getuser() {
    axios
      .get(`${baseURL}/users/${userId}`)
      .then((response) => {
        if (response.data.error) return;
        setUser(response.data.data);
      })
      .catch((err) => {
        setFormErrors({ ...initFormErrors, ...err.response.data });
        displayAlert("error", "Erreur est survenue !", "Veuiller réessayer !");
      });
  }

  useEffect(() => {
    getuser();
  }, []);

  async function save() {
    setFormErrors(initFormErrors);
    await axios
      .put(`${baseURL}/users/${userId}`, user)
      .then((response) => {
        if (response.data.error) return;
        displayAlert("success", response.data.message, "");
        navigate("/users");
      })
      .catch((err) => {
        setFormErrors({ ...initFormErrors, ...err.response.data });
        displayAlert("error", "Erreur est survenue !", "Veuiller réessayer !");
      });
  }
  return (
    <>
      <div className="breadcrumbs">
        <div className="breadcrumbs-inner">
          <div className="row m-0">
            <div className="col-sm-12">
              <div className="page-header float-left">
                <div className="page-title">
                  <h1>Modifier : {user?.name} </h1>
                </div>
              </div>
            </div>
            <div className="col-sm-8"></div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="animated">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="name" className=" form-control-label">
                    Nom
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setUser((prevState) => ({
                        ...prevState,
                        name: e.target.value,
                      }));
                    }}
                    id="name"
                    value={user?.name}
                    placeholder="Enter le nom"
                    className={
                      formErrors.name !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                  />
                  <small>
                    <code>{formErrors.name}</code>
                  </small>
                </div>
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="email" className=" form-control-label">
                    Email
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setUser((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }));
                    }}
                    id="email"
                    value={user?.email}
                    placeholder="Enter l'email"
                    className={
                      formErrors.email !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                  />
                  <small>
                    <code>{formErrors.email}</code>
                  </small>
                </div>
              </div>
              <hr />
              <div className="d-flex flex-row-reverse text-right">
                <button
                  type="button"
                  onClick={save}
                  className="btn btn-primary btn-sm mx-2"
                >
                  Enregistrer
                </button>
                <Link
                  className="btn btn-link btn-sm text-secondary mx-2"
                  to={"/users"}
                >
                  Annuler
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
