import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { baseURL } from "../../Config/config";
import { displayAlert } from "../../helpers/alert";

const defaultCandidat = {
  name: "",
  initial: "",
  title: "",
  experience: "",
  description: "",
  available: "",
  location: "",
  technologies: "",
};

const CreateCandidat = () => {
  const navigate = useNavigate();
  const { candidatId } = useParams();

  const [candidat, setCandidat] = useState(defaultCandidat);
  const [formErrors, setFormErrors] = useState(defaultCandidat);

  async function save() {
    setFormErrors(defaultCandidat);
    await axios({
      method: candidatId === undefined ? "post" : "put",
      url:
        candidatId === undefined
          ? `${baseURL}/candidats/create`
          : `${baseURL}/candidats/${candidatId}`,
      data: candidat,
    })
      .then((response) => {
        if (response.data.error) return;
        displayAlert("success", "Candidat Créé !", "");
        navigate("/candidats");
      })
      .catch((err) => {
        setFormErrors({ ...defaultCandidat, ...err.response.data });
        displayAlert("error", "Erreur est survenue !", "Veuiller réessayer !");
      });
  }

  useEffect(() => {
    if (candidatId === undefined) return;
    axios
      .get(`${baseURL}/candidats/${candidatId}`)
      .then((response) => {
        if (response.data.error) return;
        setCandidat(response.data.data);
      })
      .catch((err) => {
        setFormErrors({ ...defaultCandidat, ...err.response.data });
        displayAlert("error", "Erreur est survenue !", "Veuiller réessayer !");
      });
  }, [candidatId]);

  return (
    <>
      <div className="breadcrumbs">
        <div className="breadcrumbs-inner">
          <div className="row m-0">
            <div className="col-sm-4">
              <div className="page-header float-left">
                <div className="page-title">
                  <h1>Créer un Profils </h1>
                </div>
              </div>
            </div>
            <div className="col-sm-8"></div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="animated">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="name" className=" form-control-label">
                    Nom
                  </label>
                  <input
                    type="text"
                    className={
                      formErrors.name !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    value={candidat.name}
                    onChange={(e) => {
                      setCandidat((prevState) => ({
                        ...prevState,
                        name: e.target.value,
                      }));
                    }}
                    id="name"
                    placeholder="Enter le nom"
                  />
                  <small>
                    <code>{formErrors.name}</code>
                  </small>
                </div>
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="initial" className=" form-control-label">
                    Initial
                  </label>
                  <input
                    type="text"
                    className={
                      formErrors.initial !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    value={candidat.initial}
                    onChange={(e) => {
                      setCandidat((prevState) => ({
                        ...prevState,
                        initial: e.target.value,
                      }));
                    }}
                    id="initial"
                    placeholder="Enter le Initial"
                  />
                  <small>
                    <code>{formErrors.initial}</code>
                  </small>
                </div>
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="title" className=" form-control-label">
                    Titre
                  </label>
                  <input
                    type="text"
                    className={
                      formErrors.title !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    value={candidat.title}
                    onChange={(e) => {
                      setCandidat((prevState) => ({
                        ...prevState,
                        title: e.target.value,
                      }));
                    }}
                    id="title"
                    placeholder="Enter le Titre"
                  />
                  <small>
                    <code>{formErrors.title}</code>
                  </small>
                </div>
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="experience" className=" form-control-label">
                    Expérience
                  </label>
                  <input
                    type="text"
                    className={
                      formErrors.experience !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    value={candidat.experience}
                    onChange={(e) => {
                      setCandidat((prevState) => ({
                        ...prevState,
                        experience: e.target.value,
                      }));
                    }}
                    id="experience"
                    placeholder="Enter l'Expérience"
                  />
                  <small>
                    <code>{formErrors.experience}</code>
                  </small>
                </div>

                <div className="form-group col-12">
                  <label htmlFor="technologies" className=" form-control-label">
                    Technologies
                  </label>
                  <textarea
                    name="technologies"
                    onChange={(e) => {
                      setCandidat((prevState) => ({
                        ...prevState,
                        technologies: e.target.value,
                      }));
                    }}
                    id="technologies"
                    rows="5"
                    placeholder="Technologies ..."
                    value={candidat.technologies}
                    className={
                      formErrors.technologies !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                  ></textarea>
                  <small>
                    <code>{formErrors.technologies}</code>
                  </small>
                </div>
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="available" className=" form-control-label">
                    Disponibilté
                  </label>
                  <input
                    type="text"
                    className={
                      formErrors.available !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    value={candidat.available}
                    onChange={(e) => {
                      setCandidat((prevState) => ({
                        ...prevState,
                        available: e.target.value,
                      }));
                    }}
                    id="available"
                    placeholder="Enter la Disponibilté"
                  />
                  <small>
                    <code>{formErrors.available}</code>
                  </small>
                </div>
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="location" className=" form-control-label">
                    Mobilité
                  </label>
                  <input
                    type="text"
                    className={
                      formErrors.location !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    value={candidat.location}
                    onChange={(e) => {
                      setCandidat((prevState) => ({
                        ...prevState,
                        location: e.target.value,
                      }));
                    }}
                    id="location"
                    placeholder="Enter la Mobilité"
                  />
                  <small>
                    <code>{formErrors.location}</code>
                  </small>
                </div>
                <div className="form-group col-12">
                  <label htmlFor="description" className=" form-control-label">
                    Description
                  </label>
                  <textarea
                    name="description"
                    onChange={(e) => {
                      setCandidat((prevState) => ({
                        ...prevState,
                        description: e.target.value,
                      }));
                    }}
                    id="description"
                    rows="5"
                    value={candidat.description}
                    placeholder="Description ..."
                    className={
                      formErrors.description !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                  ></textarea>
                  <small>
                    <code>{formErrors.description}</code>
                  </small>
                </div>
              </div>
              <hr />
              <div className="d-flex flex-row-reverse text-right">
                <button
                  type="button"
                  onClick={save}
                  className="btn btn-primary btn-sm mx-2"
                >
                  Enregistrer
                </button>
                <Link
                  className="btn btn-link btn-sm text-secondary mx-2"
                  to={"/candidats"}
                >
                  Annuler
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCandidat;
