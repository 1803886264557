import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { baseURL } from "../../Config/config";
import { displayAlert } from "../../helpers/alert";

const MailingsCards = ({ mailings, refreshData }) => {
  const MySwal = withReactContent(Swal);

  function destroy(mailingId) {
    MySwal.fire({
      title: <p>Souhaitez vous supprimer ce Mailing</p>,
      confirmButtonText: "Supprimer",
      showConfirmButton: true,
      cancelButtonText: "Annuler",
      showCancelButton: true,
      focusCancel: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        axios
          .delete(`${baseURL}/mailings/${mailingId}`)
          .then((response) => {
            if (response.data.error) return;
            displayAlert("success", response.data.message, "");
            refreshData();
          })
          .catch((err) => {
            displayAlert(
              "error",
              "Erreur est survenue !",
              "Veuiller réessayer !"
            );
          });
      }
    });
  }

  return (
    <>
      <div className="row">
        {mailings &&
          mailings.map((mailing, key) => {
            return (
              <div className="col-md-6 col-12" key={key}>
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <h4 className="text-uppercase col-8 mb-2">
                        {mailing.email}
                      </h4>
                      <span className="col-4 text-right">
                        <Link
                          className="mx-1 btn btn-light text-decoration-none"
                          to={`/mailings/${mailing.id}`}
                        >
                          <i className="fa fa-pencil-square-o"></i>{" "}
                        </Link>
                        <button
                          className="btn btn-light"
                          onClick={() => destroy(mailing.id)}
                        >
                          <i className="fa fa-trash text-danger"></i>{" "}
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row mb-2">
                      <div className="col-md-12">
                        <small>
                          Client : {mailing.isClient === "yes" ? "Oui" : "Non"}{" "}
                        </small>
                      </div>
                      <div className="col-md-12">
                        <small>
                          Fournisseur :{" "}
                          {mailing.isSupplier === "yes" ? "Oui" : "Non"}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default MailingsCards;
