import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { baseURL } from "../../Config/config";
import { displayAlert } from "../../helpers/alert";

const defaultNeed = {
  title: "",
  location: "",
  link: "",
  description: "",
};

const CreateNeeds = () => {
  const navigate = useNavigate();
  const { needId } = useParams();

  const [need, setNeed] = useState(defaultNeed);
  const [formErrors, setFormErrors] = useState(defaultNeed);

  async function save() {
    setFormErrors(defaultNeed);
    if (needId === undefined) {
      await axios
        .post(`${baseURL}/needs/create`, need)
        .then((response) => {
          if (response.data.error) return;
          displayAlert("success", response.data.message, "");
          navigate("/needs");
        })
        .catch((err) => {
          setFormErrors({ ...defaultNeed, ...err.response.data });
          displayAlert(
            "error",
            "Erreur est survenue !",
            "Veuiller réessayer !"
          );
        });
    } else {
      await axios
        .put(`${baseURL}/needs/${needId}`, need)
        .then((response) => {
          if (response.data.error) return;
          displayAlert("success", response.data.message, "");
          navigate("/needs");
        })
        .catch((err) => {
          setFormErrors({ ...defaultNeed, ...err.response.data });
          displayAlert(
            "error",
            "Erreur est survenue !",
            "Veuiller réessayer !"
          );
        });
    }
  }

  useEffect(() => {
    if (needId === undefined) return;
    axios
      .get(`${baseURL}/needs/${needId}`)
      .then((response) => {
        if (response.data.error) return;
        setNeed(response.data.data);
      })
      .catch((err) => {
        setFormErrors({ ...defaultNeed, ...err.response.data });
        displayAlert("error", "Erreur est survenue !", "Veuiller réessayer !");
      });
  }, [needId]);

  return (
    <>
      <div className="breadcrumbs">
        <div className="breadcrumbs-inner">
          <div className="row m-0">
            <div className="col-sm-4">
              <div className="page-header float-left">
                <div className="page-title">
                  <h1>{needId ? "Modifier" : "Créer"} un Besoin </h1>
                </div>
              </div>
            </div>
            <div className="col-sm-8"></div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="animated">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="title" className=" form-control-label">
                    Titre
                  </label>
                  <input
                    type="text"
                    className={
                      formErrors.title !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    value={need.title}
                    onChange={(e) => {
                      setNeed((prevState) => ({
                        ...prevState,
                        title: e.target.value,
                      }));
                    }}
                    id="title"
                    placeholder="Enter le Titre"
                  />
                  <small>
                    <code>{formErrors.title}</code>
                  </small>
                </div>
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="location" className=" form-control-label">
                    Lieu de Mission
                  </label>
                  <input
                    type="text"
                    className={
                      formErrors.location !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    value={need.location}
                    onChange={(e) => {
                      setNeed((prevState) => ({
                        ...prevState,
                        location: e.target.value,
                      }));
                    }}
                    id="location"
                    placeholder="Enter le Lieu de Mission"
                  />
                  <small>
                    <code>{formErrors.location}</code>
                  </small>
                </div>
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="link" className=" form-control-label">
                    Lien de Besoin
                  </label>
                  <input
                    type="text"
                    className={
                      formErrors.link !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    value={need.link}
                    onChange={(e) => {
                      setNeed((prevState) => ({
                        ...prevState,
                        link: e.target.value,
                      }));
                    }}
                    id="link"
                    placeholder="Enter le Lieu de Mission"
                  />
                  <small>
                    <code>{formErrors.link}</code>
                  </small>
                </div>
                <div className="form-group col-12">
                  <label htmlFor="description" className=" form-control-label">
                    Description
                  </label>
                  <textarea
                    name="description"
                    onChange={(e) => {
                      setNeed((prevState) => ({
                        ...prevState,
                        description: e.target.value,
                      }));
                    }}
                    id="description"
                    rows="5"
                    value={need.description}
                    placeholder="Description ..."
                    className={
                      formErrors.description !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                  ></textarea>
                  <small>
                    <code>{formErrors.description}</code>
                  </small>
                </div>
              </div>
              <hr />
              <div className="d-flex flex-row-reverse text-right">
                <button
                  type="button"
                  onClick={save}
                  className="btn btn-primary btn-sm mx-2"
                >
                  Enregistrer
                </button>
                <Link
                  className="btn btn-link btn-sm text-secondary mx-2"
                  to={"/needs"}
                >
                  Annuler
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNeeds;
