import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { baseURL } from "../../Config/config";
import { displayAlert } from "../../helpers/alert";

const defaultMailing = {
  email: "",
  isClient: "no",
  isSupplier: "no",
};

const CreateMailing = () => {
  const navigate = useNavigate();
  const { mailingId } = useParams();

  const [mailing, setMailing] = useState(defaultMailing);
  const [formErrors, setFormErrors] = useState(defaultMailing);

  async function save() {
    setFormErrors(defaultMailing);
    await axios({
      method: mailingId === undefined ? "post" : "put",
      url:
        mailingId === undefined
          ? `${baseURL}/mailings/create`
          : `${baseURL}/mailings/${mailingId}`,
      data: mailing,
    })
      .then((response) => {
        if (response.data.error) return;
        displayAlert("success", response.data.message, "");
        navigate("/mailings");
      })
      .catch((err) => {
        setFormErrors({ ...defaultMailing, ...err.response.data });
        displayAlert("error", "Erreur est survenue !", "Veuiller réessayer !");
      });
  }

  useEffect(() => {
    if (mailingId === undefined) return;
    axios
      .get(`${baseURL}/mailings/${mailingId}`)
      .then((response) => {
        if (response.data.error) return;
        setMailing(response.data.data);
      })
      .catch((err) => {
        setFormErrors({ ...defaultMailing, ...err.response.data });
        displayAlert("error", "Erreur est survenue !", "Veuiller réessayer !");
      });
  }, [mailingId]);

  return (
    <>
      <div className="breadcrumbs">
        <div className="breadcrumbs-inner">
          <div className="row m-0">
            <div className="col-sm-4">
              <div className="page-header float-left">
                <div className="page-title">
                  <h1>{mailingId ? "Modifier" : "Créer"} un Mailing </h1>
                </div>
              </div>
            </div>
            <div className="col-sm-8"></div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="animated">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="email" className=" form-control-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className={
                      formErrors.email !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    value={mailing.email}
                    onChange={(e) => {
                      setMailing((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }));
                    }}
                    id="email"
                    placeholder="Enter le Email"
                  />
                  <small>
                    <code>{formErrors.email}</code>
                  </small>
                </div>
                <div className="row form-group col-md-6 col-12">
                  <div className="col col-md-12">
                    <label className=" form-control-label">Client</label>
                  </div>
                  <div className="col col-md-12">
                    <div className="form-check-inline form-check">
                      <label
                        htmlFor="isClient1"
                        className="form-check-label mx-2"
                      >
                        <input
                          type="radio"
                          onChange={(e) => {
                            setMailing((prevState) => ({
                              ...prevState,
                              isClient: e.target.value,
                            }));
                          }}
                          id="isClient1"
                          name="isClient"
                          value="yes"
                          checked={mailing.isClient === "yes"}
                          className="form-check-input"
                        />
                        Oui
                      </label>
                      <label
                        htmlFor="isClient2"
                        className="form-check-label mx-2"
                      >
                        <input
                          type="radio"
                          onChange={(e) => {
                            setMailing((prevState) => ({
                              ...prevState,
                              isClient: e.target.value,
                            }));
                          }}
                          id="isClient2"
                          name="isClient"
                          value="no"
                          checked={mailing.isClient === "no"}
                          className="form-check-input"
                        />
                        Non
                      </label>
                    </div>
                  </div>
                  <small>
                    <code>
                      {formErrors.isClient != "no" && formErrors.isClient}
                    </code>
                  </small>
                </div>
                <div className="row form-group col-md-6 col-12">
                  <div className="col col-md-12">
                    <label className=" form-control-label">Fournisseur</label>
                  </div>
                  <div className="col col-md-12">
                    <div className="form-check-inline form-check">
                      <label
                        htmlFor="isSupplier1"
                        className="form-check-label mx-2"
                      >
                        <input
                          type="radio"
                          onChange={(e) => {
                            setMailing((prevState) => ({
                              ...prevState,
                              isSupplier: e.target.value,
                            }));
                          }}
                          id="isSupplier1"
                          name="isSupplier"
                          value="yes"
                          checked={mailing.isSupplier === "yes"}
                          className="form-check-input"
                        />
                        Oui
                      </label>
                      <label
                        htmlFor="isSupplier2"
                        className="form-check-label mx-2"
                      >
                        <input
                          type="radio"
                          onChange={(e) => {
                            setMailing((prevState) => ({
                              ...prevState,
                              isSupplier: e.target.value,
                            }));
                          }}
                          id="isSupplier2"
                          name="isSupplier"
                          value="no"
                          checked={mailing.isSupplier === "no"}
                          className="form-check-input"
                        />
                        Non
                      </label>
                    </div>
                  </div>
                  <small>
                    <code>
                      {formErrors.isSupplier != "no" && formErrors.isSupplier}
                    </code>
                  </small>
                </div>
              </div>
              <hr />
              <div className="d-flex flex-row-reverse text-right">
                <button
                  type="button"
                  onClick={save}
                  className="btn btn-primary btn-sm mx-2"
                >
                  Enregistrer
                </button>
                <Link
                  className="btn btn-link btn-sm text-secondary mx-2"
                  to={"/mailings"}
                >
                  Annuler
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateMailing;
