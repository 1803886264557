import "./App.css";
import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate, HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "./Components/header";
import TopHeader from "./Components/topHeader";
import Login from "./Pages/login";
import axios from "axios";
import { baseURL } from "./Config/config";
import Home from "./Pages/home";
import { useSelector, useDispatch } from "react-redux";
import { store } from "./slices/userSlice";
import "react-toastify/dist/ReactToastify.css";
import { displayAlert } from "./helpers/alert";
import Users from "./Pages/users/allUsers";
import CreateUser from "./Pages/users/createUser";
import Candidats from "./Pages/candidats/allCandidats";
import UpdateUser from "./Pages/users/update";
import UpdatePassword from "./Pages/users/updatePassword";
import CreateCandidat from "./Pages/candidats/createCandidat";
import Mailings from "./Pages/mailing/allMailings";
import CreateMailing from "./Pages/mailing/createMailing";
import Needs from "./Pages/needs/allNeeds";
import CreateNeeds from "./Pages/needs/createNeed";
import CreateEmail from "./Pages/emails/createEmail";
import "bootstrap/dist/css/bootstrap.min.css";

axios.defaults.withCredentials = true;

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const user = useSelector((state) => state.user.user.payload);
  const dispatch = useDispatch();

  useEffect(() => {
    checkAuth();
  }, []);

  async function checkAuth() {
    try {
      await axios.get(`${baseURL}/checkAuth`).then((response) => {
        if (response.data.data !== null && response.data.data !== "") {
          dispatch(store(response.data.data));
        } else {
          setIsLoggedIn(true);
        }
      });
    } catch (error) {
      displayAlert("error", "Erreur est survenue !", "Veuiller réessayer !");
    }
    await setLoaded(true);
  }

  return (
    <>
      {loaded === false ? (
        ""
      ) : (
        <div>
          <HashRouter>
            {user == null ? (
              <Routes>
                <Route path="/login" exact element={<Login />}></Route>
                <Route path="*" element={<Navigate to="/login" replace />} />
              </Routes>
            ) : (
              <>
                <ToastContainer />
                <Header />
                <div id="right-panel" className="right-panel">
                  <TopHeader />
                  <div>
                    <Routes>
                      <Route
                        exact
                        path="/"
                        element={<Navigate to="/mails" replace />}
                      ></Route>
                      <Route
                        exact
                        path="/candidats"
                        element={<Candidats />}
                      ></Route>
                      <Route
                        exact
                        path="/candidats/create"
                        element={<CreateCandidat />}
                      ></Route>
                      <Route
                        exact
                        path="/candidats/:candidatId"
                        element={<CreateCandidat />}
                      ></Route>
                      <Route
                        exact
                        path="/mailings"
                        element={<Mailings />}
                      ></Route>
                      <Route
                        exact
                        path="/mailings/create"
                        element={<CreateMailing />}
                      ></Route>
                      <Route
                        exact
                        path="/mailings/:mailingId"
                        element={<CreateMailing />}
                      ></Route>
                      <Route
                        exact
                        path="/emails/create"
                        element={<CreateEmail />}
                      ></Route>
                      <Route exact path="/needs" element={<Needs />}></Route>
                      <Route
                        exact
                        path="/needs/create"
                        element={<CreateNeeds />}
                      ></Route>
                      <Route
                        exact
                        path="/needs/:needId"
                        element={<CreateNeeds />}
                      ></Route>
                      <Route exact path="/users" element={<Users />}></Route>
                      <Route
                        exact
                        path="/user/create"
                        element={<CreateUser />}
                      ></Route>
                      <Route
                        exact
                        path="/user/:userId"
                        element={<UpdateUser />}
                      ></Route>
                      <Route
                        exact
                        path="/user/update-password"
                        element={<UpdatePassword />}
                      ></Route>
                      <Route
                        path="/login"
                        element={<Navigate to="/candidats" replace />}
                      />
                    </Routes>
                  </div>
                  <div className="clearfix"></div>
                  <footer className="site-footer">
                    <div className="footer-inner bg-white">
                      <div className="row">
                        <div className="col-sm-6">
                          Copyright &copy; 2023 MSIT Mailer
                        </div>
                        <div className="col-sm-6 text-right">
                          Designed by{" "}
                          <a href="https://msit-conseil.fr">MSIT Conseil</a>
                        </div>
                      </div>
                    </div>
                  </footer>
                </div>
              </>
            )}
          </HashRouter>
        </div>
      )}
    </>
  );
}

export default App;
